@media screen and (max-width: 768px) {
  .landing-component {
    padding: 0 1rem;
    zoom: 0.9;
  }
}

.landing-component-all {
  padding: 0 1rem;
  zoom: 0.9;
}
