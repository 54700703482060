.course-image {
  height: 220px;
}

.welcome-message {
  font-size: 1.8em;
  font-weight: 700;
  margin-bottom: 0;
}

.course-heading-message {
  font-size: 1.2em;
  font-weight: 700;
  margin-top: 1em;
}

.course-heading-button {
  font-size: 1em;
  font-weight: 700;
  margin-top: 1em;
  border: 0;
  background-color: transparent;
  /* float: right; */
  position: absolute;
  /* left: 10rem; */
  right: 20px;
  bottom: 0;
}

@media screen and (min-width: 576px) {
  .course-card {
    min-height: 450px;
  }

  .all-courses {
    margin-left: 10px;
    margin-right: 10px;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 576px) {
  .session-card {
    margin-bottom: 10px;
  }

  .course-heading-text {
    /* display: none; */
  }
}

.session-card {
  /* margin: 0 */
}

.session-card-title {
  font-size: 1rem;
  font-weight: 800;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.session-card-text {
  font-size: 1rem;
  font-weight: 600;
}

.session-card .session-detail-card .session-detail-card-body {
  text-align: center;
  /* padding: 0; */
}

.session-card .session-detail-card .mt-end {
  width: 100%;
}

.course-card {
  color: black;
}

.course-card:hover {
  color: black;
  text-decoration: none;
  transform: scale(1.02);
  transition: all 0.5s ease-in-out;
  box-shadow: 2px 2px 2px #888888;
}

.course-card-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
}

.course-card-text {
  font-size: 0.85rem;
  color: black;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.empty-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-session-message {
  font-size: 1rem;
  color: black;
}

.main-container .homepage-container .container-fluid {
  /* padding: 0; */
  margin-top: 20px;
  border-bottom: 2px solid #e6e6e6;
  padding-bottom: 20px;
}

