.description {
    font-size: 1.1rem;
    color: #828282;
    margin-bottom: 10px;
}

.program {
    margin-top: 20px;
}

.selected {
    background-color: rgb(146, 213, 241);
}