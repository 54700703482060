#corporate-training .header {
  background-color: #25063a;
  padding: 5rem 5rem 5rem 5rem;
  margin: 0 0 0 0;
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: flex-start;
}

#corporate-training .container-fluid h1 {
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  text-align: center;
  width: 100%;
  line-height: 2.5rem;
}

#corporate-training .container-fluid p {
  color: #ffffff;
  font-size: 1.15rem;
  font-weight: 600;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  text-align: center;
  width: 100%;
  line-height: 5rem;
}

#corporate-training .container-fluid button {
  padding: 1rem 3rem;
}

.desc-container {
  padding: 2rem 0;
}

.desc-container h2 {
  color: #000;
  font-size: 2rem;
  font-weight: 600;
  line-height: 4.5rem;
}

.desc-container h5 {
  color: #8710d8;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.8rem;
}

.desc-container .aim-container {
  padding: 2rem 2rem;
}

.desc-container .aim-container .aim-text {
  color: #000;
  font-size: 1rem;
  font-weight: 500;
}

#corporate-training .highlights-container {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin: 0;
  width: 100%;
  top: -80px;
}

#corporate-training .highlights-container .highlight-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#corporate-training .highlights-container p {
    font-size: 0.9rem;
}

#corporate-training .highlights-container .muted-text {
    font-size: 0.8rem;
}

#corporate-training .highlights-container .highlight-svg-image {
  height: 50px;
}

#corporate-training .highlight-container .highlight-text-container {
  padding: 0 0.8rem;
}

#corporate-training .bg-light-purple {
  background-color: #eddff7;
}

#corporate-training .program-image {
  height: 200px;
  /* width: 100%; */
}