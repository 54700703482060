.spinner-container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    z-index: 999999;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: white;
    background-color: rgba(0, 0, 0, 0.5);
}

.main-spinner {
    /* position: absolute; */
    /* top: calc(50% - 110px);
    left: calc(50% - 110px); */
    width: 12vh;
    height: 12vh;
    background-color: #fff;
    background: transparent;
    border-width: 7px;
    color: #8710D8;
}

.img-spinner {
    width: 300px;
    animation-name: logo;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes logo {
    0% {
        transform: scale(1)
    }
    50% {
        transform: scale(1.2)
    } 
    100% {
        transform: scale(1)
    }    
}