.video-player {
    width: 100%;
    z-index: 1;
}

@media screen and (min-width: 768px) {
    .video-player {
        width: 75%;
    }
}
