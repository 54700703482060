/* general styling */
:root {
  --smaller: 0.75;
}

#countdowntimer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: fit-content;
  border: 1px solid #ccc;
  color: black;
  margin: 0;
  padding: 0;
  width: 100%;
}

#countdowntimer h1 {
  font-size: 1.25rem;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  font-weight: 700;
  color: black;
  width: 100%;
}

#countdown {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  background-color: #8710d8;
  color: #fff;
}

#countdowntimer li {
  display: inline-block;
  font-size: 0.7rem;
  list-style-type: none;
  padding: 0.8rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
}

#countdowntimer li span {
  display: block;
  font-size: 1.2rem;
}

@media screen and (max-width: 1000px) {

  #countdowntimer h1 {
    font-size: 1.1rem;
  }

  #countdowntimer li {
    font-size: calc(0.75rem);
  }

  #countdowntimer li span {
    font-size: calc(1rem);
  }
}
