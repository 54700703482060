.red-outline {
    border: 2px solid red;
    outline: none;
}

.red-outline:focus {
    border: 2px solid red;
    outline: none;
}

label {
    font-size: 0.9rem;
    font-weight: 600;
    color: #000;
}