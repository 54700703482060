.reviewer-image {
  height: 250px;
  /* width: 250px; */
  align-self: center;
}

.reviews-container {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  overflow-x: scroll;
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .review-card {
    width: 75vw;
  }
}
