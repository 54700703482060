.btn {
  border: none;
  border-radius: 0;
  padding: 0.8rem;
  font-size: 1.01rem;
  font-weight: 500;
  text-transform: capitalize;
  color: #fff;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  outline: none;
}

.background-primary {
  background: #8710d8;
}

.background-primary .dropdown-item:hover {
  background: #965abe;
}

.text-primary {
  color: #8710d8;
}

.btn-primary-outline {
  background: transparent;
  border: 1px solid #8710d8;
  color: #8710d8;
  font-size: 0.8rem;
  font-weight: 700;
}

.search-input {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}

.btn-primary-outline:hover {
  background: #8710d8;
  color: #fff;
}

.btn-primary {
  background: #8710d8;
  color: #fff;
  font-weight: 700;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
}

.btn-primary:hover {
  background: #8710d8;
  color: #fff;
  font-weight: 700;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
  /* box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.4); */
}

.btn-primary:focus {
  background: #8710d8;
}

.btn-primary:disabled {
  background: #301046;
  opacity: 0.5;
}

.btn-black {
  background: #000;
}

.btn-black {
  background: #000;
}

.btn-black:hover {
  background: rgb(49, 48, 48);
  color: #fff;
}

.hover-pointer {
  cursor: pointer;
}

.hover-pointer:active {
  outline: none;
  background-color: #8710d8;
}

.badge {
  border-radius: 0;
}

.card {
  border-radius: 0;
}

.card-img-top {
  border-radius: 0;
}

.btn-grey {
  background: #e2e0e0;
  color: #000;
}

.btn-grey:hover {
  background: #e2e0e0;
  color: #000;
}

.btn-grey:active {
  background: #838383;
  color: #000;
}

/* table {
  border: 1px solid #e2e0e0;
} */

.main-container {
  padding: 2vh 0 2vh 0;
}

.main-container-internal {
  margin: 2vh 0 2vh 0;
}

@media screen and (min-width: 1500px) {
  .main-container {
    margin: 1vh 125px 1vh 125px;
  }

  /* .side-card {
    position: fixed;
    left: calc((100 / 12) * 7.75%);
    width: calc((100 / 12) * 3.25%);
    font-size: smaller;
  } */
}

.registration-container {
  color: white;
}

.p-white {
  color: white;
}

.btn-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 3rem;
  border: 2px solid #8710d8;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #8710d8;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 1);
}