.curriculum-container {
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.curriculum-section-container {
    padding: 20px;
}

.curriculum-secion-header-title {
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #000;
}

.topic-heading {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #000;
    text-transform: uppercase;
}

.module-section-name {
    font-size: 1rem;
    font-weight: bold;
    /* margin-left: 10px; */
    font-family: "Montserrat", sans-serif;
    color: #000;
    text-transform: uppercase;
}

.topic-desc {
    font-size: 0.9rem;
    margin-bottom: 10px;
    color: #000000cc;
    font-family: "Montserrat", sans-serif;
}

@media (max-width: 768px) {
    .topic-heading {
        font-size: 0.8rem;
        margin-bottom: 5px;
    }

    .topic-desc {
        font-size: 0.8rem;
        margin-bottom: 5px;
    }

    .module-section-name {
        font-size: 0.8rem;
        /* padding: 5px; */
    }
}