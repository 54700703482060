.single-course-header {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #0f0f0f;
  /* background: linear-gradient(to left, #0f0f0f, #17406f); */
  background-color: #222222;
  color: #000;
  position: relative;
  box-shadow: 2px 2px 2px #0f0f0f;
  padding: 50px 10vw 80px 10vw;
}

.single-course-image {
  height: 100%;
  /* height: 30vh; */
  border: 0;
}

.single-course-name {
  /* font-size: 2em;*/
  font-weight: 600;
  color: #fff;
  margin-top: 10px;
}

.single-course-description {
  /* font-size: 1em; */
  color: #fff;
  margin: 25px 0 0 0;
}

.section-heading {
  font-size: 1.5em;
  font-weight: bold;
  color: #000;
  margin: 25px 0 0 0;
}

.section-table {
  margin: 25px 0 0 0;
}

.section-descs {
  font-size: 1em;
  color: #000;
  margin: 25px 0 0 0;
}

.all-sections {
  margin: 25px 0 0 0;
}

.section-name {
  font-size: 1rem;
  font-weight: 600;
  color: #000;
  background-color: #f0f6f6;
  margin: 10px 0 0 0;
  border: 0px solid #000;
  z-index: 500;
  border-left: 2px solid rgb(192, 186, 186, 0.5);
  box-shadow: 2px 2px 0px rgb(192, 186, 186, 0.5);
}

.ratings i {
  color: #cecece;
  font-size: 1.1rem;
}

.rating-color {
  color: #fbc634 !important;
}

.ratings {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0 0 0;
}

.review-count {
  font-size: 0.8rem;
  margin-left: 10px;
  color: #cecece;
}

.side-card .material-icons {
  font-size: 1rem;
}

.card-course-details span {
  font-size: 1rem;
}

.btn-buy {
  background-color: #8710d8;
  border-radius: 0;
  color: #fff;
  font-size: 1.05rem;
  font-weight: 600;
  padding: 1rem;
}

.btn-buy:hover {
  background-color: rgb(107, 24, 161);
  color: #fff;
  transition: 0.5s;
}

.course-price {
  font-size: 2rem;
  font-weight: bold;
  color: #000;
}

.course-price-text {
  font-size: 1rem;
  color: #000;
}

.lists {
  margin: 15px 0 0 0;
  font-size: 0.95rem;
}

.side-card-top {
  display: none;
}

@media screen and (max-width: 768px) {
  .side-card {
    display: none;
  }

  .side-card-top {
    display: block;
    margin-bottom: 20px;
  }

  .lists {
    margin: 0 0 0 0;
    font-size: 0.95rem;
    display: flex;
    flex-direction: column;
  }
}

.highlights-container {
  text-align: left;
  width: 80%;
  align-self: center;
  margin-left: 10%;
  background-color: white;
  padding: 20px;
  position: relative;
  top: -40px;
  box-shadow: 0px 0px 4px #b3b0b0;
}

.highlights-container .heading {
  font-size: 1.2rem;
  font-weight: 600;
  color: #000;
  margin: 0 0 0 0;
}

.highlights-container .muted-text {
  font-size: 0.9rem;
  color: #000;
  margin: 0 0 0 0;
}

.highlights-container-mobile {
  display: none;
  background: white;
  width: 90%;
  box-shadow: 0px 0px 4px #b3b0b0;
  padding: 20px 20px 10px 20px;
  position: relative;
  top: -45px;
  margin-left: 5%;
}

.highlights-container-mobile .heading {
  font-size: 1rem;
  font-weight: 600;
  color: #000;
}

.highlights-container-mobile .muted-text {
  font-size: 0.9rem;
  color: #000;
  margin: 0 0 0 0;
}

.variant-info-container {
  text-align: center;
  width: 100%;
  align-self: center;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 4px #b3b0b0;
}

.variant-info-container .heading {
  font-size: 1.2rem;
  font-weight: 600;
  color: #000;
  margin: 0 0 0 0;
}

.variant-info-container .muted-text {
  font-size: 0.9rem;
  color: #000;
  margin: 0 0 0 0;
}

.variant-info-container-mobile {
  display: none;
  background: white;
  width: 100%;
  box-shadow: 0px 0px 4px #b3b0b0;
  padding: 20px 20px 10px 20px;
  margin-left: 0%;
}

.variant-info-container-mobile .heading {
  font-size: 1rem;
  font-weight: 600;
  color: #000;
}

.variant-info-container-mobile .muted-text {
  font-size: 0.9rem;
  color: #000;
  margin: 0 0 0 0;
}

.cards-container {
  display: none;
}

@media screen and (max-width: 768px) {
  .highlights-container {
    display: none;
  }

  .highlights-container-mobile {
    display: block;
  }

  .variant-info-container {
    display: none;
  }

  .variant-info-container-mobile {
    display: block;
  }

  .accordion {
    display: none;
  }

  .course-modules-container {
    display: none;
  }

  .cards-container {
    display: block;
  }
}

.course-feature-container-bg-color {
  text-align: left;
  background-color: #fff;
  padding: 50px 10vw 50px 10vw;
  margin: 0;
}

.course-feature-container-bg-white {
  text-align: left;
  background-color: #edf3fa;
  padding: 50px 10vw 50px 10vw;
  margin: 0;
}

.language-tools {
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow-x: scroll;
  padding: 30px;
}

.tool-image {
  width: auto;
  height: 3rem;
  margin-right: 15px;
  margin-left: 15px;
}

.card-selected {
  /* background-color: rgba(135, 16, 216, 0.1); */
  background: linear-gradient(to right, #ecd2fd, #ecd2fd);
  color: #000;
  border-width: 0;
  border-bottom-width: 5px;
  border-color: #8710d8;
  font-weight: 800;
}

.card-selected * {
  color: #000;
}

.variant-card {
  margin-bottom: 10px;
  border-radius: 0px;
  box-shadow: 0px 0px 4px #b3b0b0;
  cursor: pointer;
}

.check-icon {
  color: #999;
  font-size: 1rem;
  margin-right: 20px;
  border: 1px solid #8710d8;
  border-radius: 50%;
  padding: 5px;
}
.check-icon-selected {
  color: #fff;
  font-size: 1rem;
  margin-right: 20px;
  border: 1px solid #8710d8;
  border-radius: 50%;
  padding: 5px;
  background-color: #8710d8;
  /* transition: background-color 0.3s ease-in-out; */
}

.best-seller-badge {
  background-color: #8710d8;
  color: #fff;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  font-size: 0.7rem;
  font-weight: 600;
  margin-left: 10px;
}

.variant-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #000;
  margin: 0 0 0 0;
}

.star-container {
  margin-top: 20px;
  list-style-type: disc;
}

.selected-variant-card {
  margin-bottom: 10px;
  border-radius: 0px;
  box-shadow: 0px 0px 4px #b3b0b0;
  cursor: pointer;
  background-color: white;
}

.variant-highlight-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 15px 0 15px;
}

.feature-list {
  /* padding: 10px 15px 10px 15px; */
}

.feature-item {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 7.5px;
  margin-top: 7.5px;
}

.feature-item-present {
  color: #8710d8;
}

.projects-container {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  /* align-items: center; */
  margin-bottom: 10px;
  padding: 0 15px 0 15px;
  overflow: hidden;
  overflow-x: scroll;
  padding-bottom: 20px;
}

.project-body {
  height: 270px;
  overflow: hidden;
}

.price-overview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.price-overview span {
  font-size: 0.8rem;
  font-weight: 600;
}

.price-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  /* align-items: baseline; */
  padding: 2rem 2.2rem;
  box-shadow: 0px 0px 4px #b3b0b0;
  margin-bottom: 10px;
}

/* .price-container .variant-name {
  display: none;
} */

.enroll-btn {
  margin-top: 20px;
}

.variant-highlight-item-text {
  font-size: 0.9rem;
}

.variant-highlight-item {
  margin-bottom: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.variant-highlight-item-icon {
  font-size: 2rem;
  color: #8710d8;
  margin-right: 10px;
}

.feature-heading {
  font-size: 1.1rem;
  font-weight: 600;
  color: #000;
  margin: 0 0 5px 0;
  padding: 10px 15px 10px 15px;
}

.redirect-card {
  margin-bottom: 10px;
  border-radius: 0px;
  box-shadow: 0px 0px 4px #b3b0b0;
  cursor: pointer;
  color: #000;
}

.redirect-card:hover {
  /* background-color: rgba(135, 16, 216, 0.9); */
  /* color: black; */
  text-decoration: none;
  /* color: white; */
  box-shadow: 0px 0px 10px #b3b0b0;
}

/* .redirect-card:hover .variant-name {
  color: white;
} */

.selected-tab {
  background-color: #8710d8;
  color: #fff;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: 600;
  margin-left: 10px;
}

.module-tab {
  background-color: #fff;
  color: #000;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: 600;
  margin-left: 10px;
}

.price-container-mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  
  .course-feature-container-bg-color {
    padding: 50px 1rem;
  }
  
  .course-feature-container-bg-white {
    padding: 50px 1rem;
  }


  .variant-highlight-item-text {
    font-size: 0.7rem;
  }

  .price-container-card {
    display: none;
  }

  .price-container-mobile {
    display: block;
    position: fixed;
    bottom: -1px;
    z-index: 500;
    background-color: white;
    left: 0;
    right: 0;
    box-shadow: 0px 0px 10px #222;
    padding: 10px;
    height: fit-content;
    transition: height 0.3s ease-in-out;
    justify-content: space-between;
    align-items: center;
  }

  .price-container-mobile.slide-top {
    /* height: 500px; */
  }

  .price-container-mobile .overview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 3rem;
    font-size: 1rem;
    color: black;
  }

  .course-price {
    font-size: 1.5rem;
    width: 50%;
    text-align: center;
  }

  .enroll-btn {
    margin-top: 0;
  }
}

.aim-container {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  padding: 20px 20px 20px 20px;
  background-color: white;
}

.img-aim {
  width: 70%;
  height: auto;
}

.course-modules-container .all-modules {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  border-bottom: 1px solid #e0e0e0;
  /* overflow: hidden;
  overflow-x: scroll; */
}

.tabs .module-name {
  font-size: 1rem;
  font-weight: 600;
  color: #000;
  margin: 0 0 0 0;
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.tabs .active-module-name {
  font-size: 1rem;
  font-weight: 600;
  color: #8710d8;
  margin: 0 0 0 0;
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.section-duration {
  font-size: 0.9rem;
  color: #000;
  margin: 0 0 0 0;
  text-align: center;
  position: absolute;
  right: 10%;
}

.topic-container {
  margin: 10px 5px 50px 5px;
}

.topic-name {
  font-size: 1rem;
  font-weight: 600;
  color: #000;
  margin: 0 0 0 0;
  cursor: pointer;
}

.topic-description {
  font-size: 0.85rem;
  color: #000;
  margin: 0 0 0 0;
}

.accordion-item {
  background: #fff;
}
