.header .container-fluid {
  margin: 0;
  padding: 0;
}

.header .header-container {
  background-color: #25063a;
  padding: 3rem 5rem 5rem 5rem;
  margin: 0 0 0 0;
  width: 100%;
  display: flex;
  
  justify-content: center;
  align-items: flex-start;
}

.header .button-container {
  margin-top: 3rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.header .header-left-container {
  padding: 2rem 0;
}

.header .header-image {
  width: 80%;
}

.header .header-image-mobile {
  display: none;
}

.header .header-heading {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  line-height: 3rem;
}

.header h2 {
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}

.header h4 {
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
}

.header h5 {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  margin: 0.5rem 0;
}

.header .highlights-container {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.header .highlights-container .highlight-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .highlights-container .highlight-svg-image {
  height: 60px;
}

.header .highlight-container .highlight-text-container {
  padding: 0 1rem;
}

@media screen and (max-width: 1500px) {
  .header .header-container {
    padding: 2rem 1.5rem 4rem 1.5rem;
  }

  .header .header-heading {
    font-size: 2rem;
    line-height: 2.3rem;
  }

  .header h2 {
    font-size: 1.2rem;
  }

  .header h5 {
    font-size: 0.9rem;
  }

  .header .header-image-container {
    padding: 0;
  }

  .header .header-image {
    display: none;
  }

  .header .header-image-mobile {
    display: block;
    width: 75%;
    margin-top: 2rem;
  }

  .header .highlights-container {
    display: flex;
  }

  .header .highlight-container {
    flex-direction: column;
    padding: 1rem 0;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .header .highlight-container .highlight-svg-image {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

  .header .button-container {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
  }

  .header .button-container button {
    margin: 1rem 0;
  }
}
