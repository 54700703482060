.interview-course-header {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #0f0f0f;
  background: white;
  color: #000;
  position: relative;
  box-shadow: 0px 0px 1px #2b2929;
  padding: 50px 10vw 80px 10vw;
}

.interview-course-image {
  height: 100%;
  /* height: 30vh; */
  border: 0;
}

.interview-course-name {
  /* font-size: 2em;*/
  font-weight: 600;
  color: #000;
  margin-top: 10px;
}

.interview-course-description {
  /* font-size: 1em; */
  color: #000;
  margin: 25px 0 0 0;
}

.text-black {
  color: #000;
}

.interv i {
  color: #000;
  font-size: 2rem;
}

.reviews-count {
  font-size: 1rem;
  color: #000;
  margin-left: 10px;
}

.rating-color {
  color: #fbc634 !important;
}
