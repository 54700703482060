.posts-cards {
  display: none;
}

.posts-table {
  display: block;
}

.latest-title {
  margin-bottom: 0;
  font-size: 1.7rem;
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .posts-table {
    display: none;
  }

  .posts-cards {
    display: block;
  }

  .card-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .post-title {
    margin-bottom: 0;
    font-size: 1rem;
  }

  .posts-cards .card-body {
    padding: 0.5rem 10px 0.5rem 10px;
  }

  .posts-cards .card {
    margin-bottom: 5px;
  }

  .card-tags {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: -10px;
  }

  .latest-title {
    margin-bottom: 0;
    font-size: 1.25rem;
    font-weight: 500;
  }

  .latest-title .btn {
    font-size: 0.75rem;
    font-weight: 500;
  }
}

.post-title-link {
  color: #000;
}

.post-title-link:hover {
  color: #000;
}

.table-row {
  padding: 0;
}

.badge-tag {
  background: #8710d8;
  color: #fff;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 500;
  margin-right: 0.5rem;
}

.badge-category {
  background: #109fd8;
  color: #fff;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 500;
  margin-right: 0.5rem;
  border: 0;
}

.pagination-container .page-link {
  color: #8710d8;
}

.pagination-container .page-item.active .page-link {
  background: #8710d8;
  color: #fff;
}

.badge-tag:hover {
  background: #8710d8;
  color: #fff;
  text-decoration: underline;
}
