/* .navbar {
  padding-right: 100px;
} */

.nav-custom {
  font: 600 0.875rem/0.875rem "Montserrat", sans-serif;
  transition: all 0.2s;
  box-shadow: 0 0.025rem 0.525rem rgba(0, 0, 0, 0.775);
}

.logo-image {
  width: 200px;
  height: 40px;
  margin-left: 10px;
}

.nav-link {
  color: white;
  padding: 10px 10px 10px 10px;
  transition: 0.1s ease-in-out;
}

.text-dark {
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav {
  font-weight: 600;
  color: black;
}

.navbar-nav .active-page-link {
  color: #fff;
  font-weight: 600;
  border-bottom: 2px solid #fff;
  padding: 0 5px 8px 5px;
}

.navbar-nav .page-link {
  color: #fff;
  font-weight: 600;
  padding: 0 5px 8px 5px;
  background-color: transparent;
  border: none;
}

.nav-link:hover {
  color: #fff;
  z-index: 50;
  cursor: pointer;
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .navbar {
    padding-right: 0px;
  }
}

.nav-search-button {
  background: transparent;
  font-size: 1.5em;
  color: rgb(49, 143, 88);
  cursor: pointer;
  position: relative;
  left: -45px;
  border: none;
  justify-content: center;
  align-items: center;
  top: 3px;
}

.search-input {
  padding-right: 45px;
}

.nav-link-icon {
  display: none;
}
.search-form-nav {
  display: none;
}

.search-form {
  margin-left: 20vw;
}

@media screen and (max-width: 1140px) {
  .search-form {
    margin-left: 7vw;
  }
}

@media screen and (max-width: 1025px) {
  .nav-link {
    display: none;
  }

  .nav-link-icon {
    display: block;
    margin-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .search-form {
    display: none;
  }

  .search-form-nav {
    width: 100vw;
    display: flex;
  }

  .nav-link {
    display: block;
  }

  .nav-link-icon {
    display: none;
  }

  /* .nav-search-button {
      color: white;
      position: relative;
      left: -45px;
      border: none;
      top: 3px;
    } */
}
