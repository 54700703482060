/* Template: Corso - Free Training Course Landing Page Template
   Author: Inovatik
   Created: Nov 2019
   Description: Master CSS file
*/

/*****************************************
Table Of Contents:

01. General Styles
02. Preloader
03. Navigation
04. Header
05. Registration
06. Partners
07. Instructor
08. Description
09. Students
10. Details Lightbox
11. Video
12. Takeaways
13. Testimonials
14. Date
15. Newsletter
16. Contact
17. Footer
18. Copyright
19. Back To Top Button
20. Extra Pages
21. Media Queries
******************************************/

/*****************************************
Colors:
- Backgrounds - light gray #fbf9f5
- Backgrounds, buttons - yellow #8710d8
- Headings text, navbar links - black #333
- Body text - dark gray #555
******************************************/

/******************************/
/*     01. General Styles     */
/******************************/
body,
html {
  width: 100%;
  height: 100%;
}

body,
p {
  color: #555;
  font: 400 1rem/1.625rem "Montserrat", sans-serif;
}

.p-large {
  font: 400 1.125rem/1.75rem "Montserrat", sans-serif;
}

.p-small {
  font: 400 0.875rem/1.5rem "Montserrat", sans-serif;
}

.p-heading {
  margin-bottom: 3.75rem;
}

h1 {
  color: #333;
  font: 700 2.75rem/3.375rem "Montserrat", sans-serif;
}

h2 {
  color: #333;
  font: 700 2.25rem/2.875rem "Montserrat", sans-serif;
}

h3 {
  color: #333;
  font: 700 1.625rem/2.125rem "Montserrat", sans-serif;
}

h4 {
  color: #333;
  font: 700 1.25rem/1.875rem "Montserrat", sans-serif;
}

h5 {
  color: #333;
  font: 700 1.125rem/1.75rem "Montserrat", sans-serif;
}

h6 {
  color: #333;
  font: 700 1rem/1.625rem "Montserrat", sans-serif;
}

.testimonial-text {
  font-style: italic;
}

.testimonial-author {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.625rem;
}

.li-space-lg li {
  margin-bottom: 0.375rem;
}

.indent {
  padding-left: 1.25rem;
}

.white {
  color: #eee;
}

.bold-400 {
  font-weight: 400;
}

.bold-500 {
  font-weight: 500;
}

.bold-600 {
  font-weight: 600;
}

.bold-700 {
  font-weight: 700;
}

.btn-solid-reg {
  display: inline-block;
  padding: 1.1875rem 2.125rem 1.1875rem 2.125rem;
  border: 0.125rem solid #8710d8;
  border-radius: 2rem;
  background-color: #8710d8;
  color: #333;
  font: 600 0.875rem/0 "Montserrat", sans-serif;
  text-decoration: none;
  transition: all 0.2s;
}

.btn-solid-reg:hover {
  border: 0.125rem solid #333;
  background-color: transparent;
  color: #333;
  text-decoration: none;
}

.btn-solid-lg {
  display: inline-block;
  padding: 1.375rem 2.625rem 1.375rem 2.625rem;
  border: 0.125rem solid #8710d8;
  border-radius: 2rem;
  background-color: #8710d8;
  color: #fff;
  font: 600 0.875rem/0 "Montserrat", sans-serif;
  text-decoration: none;
  transition: all 0.2s;
}

.btn-solid-lg:hover {
  border: 0.125rem solid #fff;
  background-color: transparent;
  color: #fff;
  text-decoration: none;
}

.btn-outline-reg {
  display: inline-block;
  padding: 1.1875rem 2.125rem 1.1875rem 2.125rem;
  border: 0.125rem solid #333;
  border-radius: 2rem;
  background-color: transparent;
  color: #333;
  font: 600 0.875rem/0 "Montserrat", sans-serif;
  text-decoration: none;
  transition: all 0.2s;
}

.btn-outline-reg:hover {
  border: 0.125rem solid #8710d8;
  background-color: #8710d8;
  color: #333;
  text-decoration: none;
}

.btn-outline-lg {
  display: inline-block;
  padding: 1.375rem 2.625rem 1.375rem 2.625rem;
  border: 0.125rem solid #333;
  border-radius: 2rem;
  background-color: transparent;
  color: #333;
  font: 600 0.875rem/0 "Montserrat", sans-serif;
  text-decoration: none;
  transition: all 0.2s;
}

.btn-outline-lg:hover {
  border: 0.125rem solid #8710d8;
  background-color: #8710d8;
  color: #333;
  text-decoration: none;
}

.btn-outline-sm {
  display: inline-block;
  padding: 1rem 1.625rem 0.875rem 1.625rem;
  border: 0.125rem solid #333;
  border-radius: 2rem;
  background-color: transparent;
  color: #333;
  font: 600 0.875rem/0 "Montserrat", sans-serif;
  text-decoration: none;
  transition: all 0.2s;
}

.btn-outline-sm:hover {
  border: 0.125rem solid #8710d8;
  background-color: #8710d8;
  color: #333;
  text-decoration: none;
}

.form-group {
  position: relative;
  margin-bottom: 1.25rem;
}

.form-group.has-error.has-danger {
  margin-bottom: 0.625rem;
}

.form-group.has-error.has-danger .help-block.with-errors ul {
  margin-top: 0.375rem;
}

.label-control {
  position: absolute;
  top: 0.87rem;
  left: 1.375rem;
  color: #555;
  opacity: 1;
  font: 400 0.875rem/1.375rem "Montserrat", sans-serif;
  cursor: text;
  transition: all 0.2s ease;
}

/* IE10+ hack to solve lower label text position compared to the rest of the browsers */
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .label-control {
    top: 0.9375rem;
  }
}

.form-control-input:focus + .label-control,
.form-control-input.notEmpty + .label-control,
.form-control-textarea:focus + .label-control,
.form-control-textarea.notEmpty + .label-control {
  top: 0.125rem;
  opacity: 1;
  font-size: 0.75rem;
  font-weight: 700;
}

.form-control-input,
.form-control-select {
  display: block; /* needed for proper display of the label in Firefox, IE, Edge */
  width: 100%;
  padding-top: 1.0625rem;
  padding-bottom: 0.0625rem;
  padding-left: 1.3125rem;
  border: 1px solid #c4d8dc;
  border-radius: 0.25rem;
  background-color: #fff;
  color: #555;
  font: 400 0.875rem/1.875rem "Montserrat", sans-serif;
  transition: all 0.2s;
  -webkit-appearance: none; /* removes inner shadow on form inputs on ios safari */
}

.form-control-select {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 3rem;
}

/* IE10+ hack to solve lower label text position compared to the rest of the browsers */
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .form-control-input {
    padding-top: 1.25rem;
    padding-bottom: 0.75rem;
    line-height: 1.75rem;
  }

  .form-control-select {
    padding-top: 0.875rem;
    padding-bottom: 0.75rem;
    height: 3.125rem;
    line-height: 2.125rem;
  }
}

select {
  /* you should keep these first rules in place to maintain cross-browser behavior */
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url("./assets/images/down-arrow.png");
  background-position: 96% 50%;
  background-repeat: no-repeat;
  outline: none;
}

select::-ms-expand {
  display: none; /* removes the ugly default down arrow on select form field in IE11 */
}

.form-control-textarea {
  display: block; /* used to eliminate a bottom gap difference between Chrome and IE/FF */
  width: 100%;
  height: 8rem; /* used instead of html rows to normalize height between Chrome and IE/FF */
  padding-top: 1.25rem;
  padding-left: 1.3125rem;
  border: 1px solid #c4d8dc;
  border-radius: 0.25rem;
  background-color: #fff;
  color: #555;
  font: 400 0.875rem/1.75rem "Montserrat", sans-serif;
  transition: all 0.2s;
}

.form-control-input:focus,
.form-control-select:focus,
.form-control-textarea:focus {
  border: 1px solid #a1a1a1;
  outline: none; /* Removes blue border on focus */
}

.form-control-input:hover,
.form-control-select:hover,
.form-control-textarea:hover {
  border: 1px solid #a1a1a1;
}

.checkbox {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

input[type="checkbox"] {
  vertical-align: -15%;
  margin-right: 0.375rem;
}

/* IE10+ hack to raise checkbox field position compared to the rest of the browsers */
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  input[type="checkbox"] {
    vertical-align: -9%;
  }
}

.form-control-submit-button {
  display: inline-block;
  width: 100%;
  height: 3.125rem;
  border: 0.125rem solid #fff;
  border-radius: 1.5rem;
  background-color: #fff;
  color: #333;
  font: 600 0.875rem/0 "Montserrat", sans-serif;
  cursor: pointer;
  transition: all 0.2s;
}

.form-control-submit-button:hover {
  border: 0.125rem solid #333;
  background-color: transparent;
  color: #333;
}

/* Form Success And Error Message Formatting */
#rmsgSubmit.h3.text-center.tada.animated,
#nmsgSubmit.h3.text-center.tada.animated,
#cmsgSubmit.h3.text-center.tada.animated,
#pmsgSubmit.h3.text-center.tada.animated,
#rmsgSubmit.h3.text-center,
#nmsgSubmit.h3.text-center,
#cmsgSubmit.h3.text-center,
#pmsgSubmit.h3.text-center {
  display: block;
  margin-bottom: 0;
  color: #333;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1rem;
}

.help-block.with-errors .list-unstyled {
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: left;
}

.help-block.with-errors ul {
  margin-bottom: 0;
}
/* end of form success and error message formatting */

/* Form Success And Error Message Animation - Animate.css */
@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
/* end of form success and error message animation - Animate.css */

/* Fade-move Animation For Details Lightbox - Magnific Popup */
/* at start */
.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  transition: all 0.2s ease-out;
  -webkit-transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
  -ms-transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
  transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translateY(0) perspective(37.5rem) rotateX(0);
  -ms-transform: translateY(0) perspective(37.5rem) rotateX(0);
  transform: translateY(0) perspective(37.5rem) rotateX(0);
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;
  -webkit-transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg);
  -ms-transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg);
  transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg);
}

/* dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
  opacity: 0.8;
}
/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0;
}
/* end of fade-move animation for details lightbox - magnific popup */

/* Fade Animation For Image Lightbox - Magnific Popup */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation: fadeIn 0.6s;
  animation: fadeIn 0.6s;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation: fadeOut 0.8s;
  animation: fadeOut 0.8s;
}
/* end of fade animation for details lightbox - magnific popup */

/*************************/
/*     02. Preloader     */
/*************************/
.spinner-wrapper {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fbf9f5;
}

.spinner {
  position: absolute;
  top: 50%; /* centers the loading animation vertically one the screen */
  left: 50%; /* centers the loading animation horizontally one the screen */
  width: 3.75rem;
  height: 1.25rem;
  margin: -0.625rem 0 0 -1.875rem; /* is width and height divided by two */
  text-align: center;
}

.spinner > div {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: #8710d8;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

/**************************/
/*     03. Navigation     */
/**************************/
.navbar-custom {
  background-color: #fff;
  box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.04);
  font: 600 0.875rem/0.875rem "Montserrat", sans-serif;
  transition: all 0.2s;
}

.navbar-custom .navbar-brand.logo-image img {
  width: 14.5625rem;
  height: 2.4rem;
}

.navbar-custom .navbar-brand.logo-text {
  font: italic 600 2rem/1.5rem "Montserrat", sans-serif;
  color: #8710d8;
  text-decoration: none;
}

.navbar-custom .navbar-nav {
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}

.nav-custom .navbar-nav {
  margin-right: 100px;
}

.navbar-custom .nav-item .nav-link {
  padding: 0.625rem 0.75rem;
  color: #333;
  text-decoration: none;
  transition: all 0.2s ease;
}

.navbar-custom .nav-item .nav-link:hover,
.navbar-custom .nav-item .nav-link.active {
  color: #8710d8;
}

/* Dropdown Menu */
.navbar-custom .dropdown:hover > .dropdown-menu {
  display: block; /* this makes the dropdown menu stay open while hovering it */
  min-width: auto;
  animation: fadeDropdown 0.2s; /* required for the fade animation */
}

@keyframes fadeDropdown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navbar-custom .dropdown-toggle:focus {
  /* removes dropdown outline on focus */
  outline: 0;
}

.navbar-custom .dropdown-menu {
  margin-top: 0;
  border: none;
  border-radius: 0.25rem;
  background-color: #fff;
}

.navbar-custom .dropdown-item {
  color: #333;
  text-decoration: none;
}

.navbar-custom .dropdown-item:hover {
  background-color: #fff;
}

.navbar-custom .dropdown-item .item-text {
  font: 600 0.875rem/0.875rem "Montserrat", sans-serif;
}

.navbar-custom .dropdown-item:hover .item-text {
  color: #8710d8;
}

.navbar-custom .dropdown-divider {
  width: 100%;
  height: 1px;
  margin: 0.75rem auto 0.725rem auto;
  border: none;
  background-color: #c4d8dc;
  opacity: 0.2;
}
/* end of dropdown menu */

.navbar-custom .social-icons {
  display: none;
}

.navbar-custom .navbar-toggler {
  padding: 0;
  border: none;
  color: #333;
  font-size: 2rem;
}

.navbar-custom
  button[aria-expanded="false"]
  .navbar-toggler-awesome.fas.fa-times {
  display: none;
}

.navbar-custom
  button[aria-expanded="false"]
  .navbar-toggler-awesome.fas.fa-bars {
  display: inline-block;
}

.navbar-custom
  button[aria-expanded="true"]
  .navbar-toggler-awesome.fas.fa-bars {
  display: none;
}

.navbar-custom
  button[aria-expanded="true"]
  .navbar-toggler-awesome.fas.fa-times {
  display: inline-block;
  margin-right: 0.125rem;
}

/*********************/
/*    04. Header     */
/*********************/
.header {
  text-align: center;
}


.header .btn-solid-lg:hover {
  border: 0.125rem solid #fff;
  color: #fff;
}

.header .btn-outline-lg {
  border: 0.125rem solid #fff;
  color: #fff;
}

.header .btn-outline-lg:hover {
  background-color: #fff;
  color: #333;
}

/****************************/
/*     05. Registration     */
/****************************/

.form-1 {
  background-color: #fff;
}

.form-1 h2 {
  margin-bottom: 1.25rem;
}

.form-1 p,
.form-1 .list-unstyled,
.form-1 .help-block,
.form-1 .checkbox,
.form-1 .checkbox a {
  color: #333;
}

.form-1 p {
  margin-bottom: 1.5rem;
}

.form-1 .list-unstyled .fas {
  color: #333;
  font-size: 0.5rem;
  line-height: 1.5rem;
}

.form-1 .list-unstyled .media-body {
  margin-left: 0.625rem;
}

.form-1 .form-control-input {
  border: 1px solid #8710d8;
  background-color: #fff;
}

.form-1 .form-control-input:hover {
  border: 2px solid #8710d8;
}

.form-1 .form-control-submit-button {
  border: 0.125rem solid #8710d8;
  background-color: #fff;
  color: #8710d8;
}

.form-1 .form-control-submit-button:hover {
  border: 0.125rem solid #fff;
  background-color: #8710d8;
  color: #fff;
}

/************************/
/*     06. Partners     */
/************************/
.slider-1 {
  padding-top: 3.5rem;
  padding-bottom: 3rem;
  background-color: #fbf9f5;
  text-align: center;
}

.slider-1 .p-small {
  color: #797fa7;
}

.slider-1 .slider-container {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

/**************************/
/*     07. Instructor     */
/**************************/
.basic-1 {
  padding-top: 3.75rem;
  padding-bottom: 3.5rem;
  background-color: #fbf9f5;
}

.basic-1 h2 {
  margin-bottom: 1.25rem;
}

.basic-1 img {
  margin-bottom: 2.5rem;
  border-radius: 0.25rem;
}

/***************************/
/*     08. Description     */
/***************************/
.basic-2 {
  padding-top: 3.5rem;
  padding-bottom: 2.75rem;
}

.basic-2 h2 {
  margin-bottom: 3.75rem;
  text-align: center;
}

.basic-2 .li-space-lg li {
  margin-bottom: 1.375rem;
}

.basic-2 .list-unstyled .bullet {
  margin-right: 0.625rem;
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 50%;
  background-color: rgb(238, 230, 230);
  color: #333;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 2rem;
  text-align: center;
}

/************************/
/*     09. Students     */
/************************/
.basic-3 {
  padding-top: 2.75rem;
  padding-bottom: 4rem;
  background-color: #fbf9f5;
}

.basic-3 .text-container {
  margin-bottom: 3.5rem;
}

.basic-3 h2 {
  margin-bottom: 1.25rem;
}

.basic-3 p {
  margin-bottom: 1.375rem;
}

.basic-3 img {
  border-radius: 0.25rem;
}

/********************************/
/*     10. Details Lightbox     */
/********************************/
.lightbox-basic {
  margin: 2.5rem auto;
  padding: 2rem 1.5rem 2rem 1.5rem;
  border-radius: 0.25rem;
  background: #fbf9f5;
  text-align: left;
}

.lightbox-basic .container {
  padding-right: 0;
  padding-left: 0;
}

.lightbox-basic .image-container {
  max-width: 33.75rem;
  margin-right: auto;
  margin-bottom: 3rem;
  margin-left: auto;
}

.lightbox-basic .image-container img {
  border-radius: 0.25rem;
}

.lightbox-basic h3 {
  margin-bottom: 0.5rem;
}

.lightbox-basic hr {
  width: 2.5rem;
  height: 0.125rem;
  margin-top: 0;
  margin-bottom: 0.875rem;
  margin-left: 0;
  border: 0;
  background-color: #555;
  text-align: left;
}

.lightbox-basic h4 {
  margin-bottom: 1rem;
}

.lightbox-basic .list-unstyled .fas {
  color: #555;
  font-size: 0.5rem;
  line-height: 1.5rem;
}

.lightbox-basic .list-unstyled .media-body {
  margin-left: 0.625rem;
}

.lightbox-basic .btn-outline-reg,
.lightbox-basic .btn-solid-reg {
  margin-top: 0.75rem;
}

/* Signup Button */
.lightbox-basic .btn-solid-reg.mfp-close {
  position: relative;
  width: auto;
  height: auto;
  color: #333;
  opacity: 1;
}

.lightbox-basic .btn-solid-reg.mfp-close:hover {
  color: #333;
}
/* end of signup Button */

/* Back Button */
.lightbox-basic a.mfp-close.as-button {
  position: relative;
  width: auto;
  height: auto;
  margin-left: 0.375rem;
  color: #333;
  opacity: 1;
}

.lightbox-basic a.mfp-close.as-button:hover {
  color: #333;
}
/* end of back button */

.lightbox-basic button.mfp-close.x-button {
  position: absolute;
  top: -0.125rem;
  right: -0.125rem;
  width: 2.75rem;
  height: 2.75rem;
  color: #707984;
}

/*********************/
/*     11. Video     */
/*********************/
.basic-4 {
  padding-top: 4rem;
  padding-bottom: 3rem;
  background-color: #fbf9f5;
}

.basic-4 h2 {
  margin-bottom: 2.75rem;
  text-align: center;
}

.basic-4 .image-container {
  margin-bottom: 2rem;
}

.basic-4 .image-container img {
  border-radius: 0.75rem;
}

.basic-4 .video-wrapper {
  position: relative;
}

/* Video Play Button */
.basic-4 .video-play-button {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  display: block;
  box-sizing: content-box;
  width: 2rem;
  height: 2.75rem;
  padding: 1.125rem 1.25rem 1.125rem 1.75rem;
  border-radius: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.basic-4 .video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  display: block;
  width: 4.75rem;
  height: 4.75rem;
  border-radius: 50%;
  background: #8710d8;
  animation: pulse-border 1500ms ease-out infinite;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.basic-4 .video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  display: block;
  width: 4.375rem;
  height: 4.375rem;
  border-radius: 50%;
  background: #8710d8;
  transition: all 200ms;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.basic-4 .video-play-button span {
  position: relative;
  display: block;
  z-index: 3;
  top: 0.375rem;
  left: 0.25rem;
  width: 0;
  height: 0;
  border-left: 1.625rem solid #fff;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
/* end of video play button */

/*************************/
/*     12. Takeaways     */
/*************************/
.cards {
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.cards h2 {
  margin-bottom: 1rem;
  text-align: center;
}

.cards p {
  text-align: center;
}

.cards .card {
  max-width: 21rem;
  margin-right: auto;
  margin-bottom: 4rem;
  margin-left: auto;
  padding: 3rem 1.625rem 1.75rem 1.625rem;
  border: 1 solid #eaeaea;
  background-color: #fff;
  text-align: center;
}

.cards .card-image {
  width: 6rem;
  height: 6rem;
  margin-right: auto;
  margin-bottom: 1.5rem;
  margin-left: auto;
  border-radius: 50%;
  background-color: #fbf9f5;
}

.cards .fas,
.cards .far {
  color: #8710d8;
  font-size: 3rem;
  line-height: 6rem;
}

.cards .card-body {
  padding: 0;
}

.cards .card-title {
  margin-bottom: 0.5rem;
}

/****************************/
/*     13. Testimonials     */
/****************************/
.slider-2 {
  padding-top: 2.5rem;
  padding-bottom: 4rem;
  background-color: #fbf9f5;
}

.slider-2 h3 {
  margin-bottom: 3.75rem;
  text-align: center;
}

.slider-2 .slider-container {
  position: relative;
}

.slider-2 .swiper-container {
  position: static;
  width: 88%;
  text-align: center;
}

.slider-2 .image-wrapper {
  width: 6rem;
  margin-right: auto;
  margin-bottom: 1.5rem;
  margin-left: auto;
}

.slider-2 .image-wrapper img {
  border-radius: 0.25rem;
}

.slider-2 .testimonial-text {
  margin-bottom: 0.5rem;
}

.slider-2 .testimonial-author {
  color: #333;
}

.slider-2 .swiper-button-prev,
.slider-2 .swiper-button-next {
  width: 1.125rem;
}

.slider-2 .swiper-button-prev:focus,
.slider-2 .swiper-button-next:focus {
  /* even if you can't see it chrome you can see it on mobile device */
  outline: none;
}

.slider-2 .swiper-button-prev {
  left: -0.5rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23505c67'%2F%3E%3C%2Fsvg%3E");
  background-size: 1.125rem 1.75rem;
}

.slider-2 .swiper-button-next {
  right: -0.5rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23505c67'%2F%3E%3C%2Fsvg%3E");
  background-size: 1.125rem 1.75rem;
}

/********************/
/*     14. Date     */
/********************/

/**************************/
/*     15. Newsletter     */
/**************************/
.form-2 {
  padding-top: 8.125rem;
  padding-bottom: 5.75rem;
  background-color: #fbf9f5;
}

.form-2 h3 {
  margin-bottom: 3.25rem;
  text-align: center;
}

.form-2 form {
  margin-bottom: 3rem;
}

.form-2 .icon-container {
  text-align: center;
}

.form-2 .fa-stack {
  width: 2em;
  margin-bottom: 0.75rem;
  margin-right: 0.375rem;
  font-size: 1.5rem;
}

.form-2 .fa-stack .fa-stack-1x {
  color: #333;
  transition: all 0.2s ease;
}

.form-2 .fa-stack .fa-stack-2x {
  color: #8710d8;
  transition: all 0.2s ease;
}

.form-2 .fa-stack:hover .fa-stack-1x {
  color: #8710d8;
}

.form-2 .fa-stack:hover .fa-stack-2x {
  color: #333;
}

/***********************/
/*     16. Contact     */
/***********************/
.form-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #25063a;
  color: #000;
}

.form-3 .text-container {
  margin-bottom: 3rem;
}

.form-3 h2 {
  margin-bottom: 1.125rem;
}

.form-3 p {
  color: #333;
  margin-bottom: 2rem;
}

.form-3 h3 {
  margin-bottom: 0.875rem;
}

.form-3 .list-unstyled,
.form-3 .list-unstyled a {
  color: #333;
}

.form-3 .list-unstyled .fas {
  color: #333;
  font-size: 1rem;
  line-height: 1.625rem;
}

.form-3 .list-unstyled .media-body {
  margin-left: 0.75rem;
}

.form-3 .list-unstyled .media-body .fas.fa-globe {
  margin-left: 1.125rem;
  margin-right: 0.75rem;
}

.form-3 .form-control-input,
.form-3 .form-control-textarea {
  border: 1px solid #f0f0f0;
  background-color: #fff;
  color: #000;
}


.form-3 .help-block,
.form-3 .checkbox,
.form-3 .checkbox a {
  color: #000;
}

.form-3 .form-control-submit-button {
  border: 0.125rem solid #25063a;
  background-color: #fff;
  color: #8710d8;
  font-weight: 700;
}

.form-3 .form-control-submit-button:hover {
  border: 0.125rem solid #fff;
  background-color: #25063a;
  color: #fff;
}

/**********************/
/*     17. Footer     */
/**********************/
.footer {
  padding-top: 4.75rem;
  padding-bottom: 2rem;

  background-color: #000;
  background-size: cover;
}

.footer .footer-col {
  margin-bottom: 2.25rem;
}

.footer h5 {
  margin-bottom: 0.5rem;
}

.footer a {
  text-decoration: none;
}

.footer h5,
.footer p,
.footer a {
  color: #fff;
}

.footer .list-unstyled .fas {
  color: #fff;
  font-size: 0.5rem;
  line-height: 1.375rem;
}

.footer .list-unstyled .media-body {
  margin-left: 0.625rem;
}

.footer .fab {
  margin-bottom: 0.75rem;
  margin-right: 1rem;
  color: #fff;
  font-size: 1.25rem;
  transition: all 0.2s ease;
}

.footer .fab:hover {
  color: #8710d8;
}

/*************************/
/*     18. Copyright     */
/*************************/
.copyright {
  padding-top: 1.5rem;
  padding-bottom: 0.375rem;
  background-color: #000;
  text-align: center;
}

.copyright .p-small {
  color: #999;
  font-size: 0.75rem;
  line-height: 1.375rem;
}

.copyright a {
  color: #999;
}

/**********************************/
/*     19. Back To Top Button     */
/**********************************/
a.back-to-top {
  position: fixed;
  z-index: 999;
  right: 0.75rem;
  bottom: 0.75rem;
  display: none;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 1.875rem;
  background: #8710d8 url("./assets/images/up-arrow.png") no-repeat center 47%;
  background-size: 1.125rem 1.125rem;
  text-indent: -9999px;
}

a:hover.back-to-top {
  background-color: #f0a731;
}


/*****************************/
/*     21. Media Queries     */
/*****************************/
/* Min-width width 768px */
@media (min-width: 768px) {
  /* General Styles */
  .p-heading {
    width: 85%;
    margin-right: auto;
    margin-left: auto;
  }
  /* end of general styles */

  .header h1 {
    font-size: 4rem;
    line-height: 4.875rem;
    letter-spacing: -0.3px;
  }

  .header .btn-solid-lg {
    margin-bottom: 0;
    margin-left: 0;
  }

  /* end of header */

  /* Registration */

  /* end of registration */

  /* Description */
  .basic-2 h2 {
    width: 41rem;
    margin-right: auto;
    margin-left: auto;
  }

  .basic-2 .list-unstyled .bullet {
    width: 3.25rem;
    height: 3.25rem;
    margin-right: 1rem;
    font-size: 1.375rem;
    line-height: 3.25rem;
  }
  /* end of description */

  /* Newsletter */
  .form-2 form {
    width: 31.875rem;
    margin-right: auto;
    margin-left: auto;
  }
  /* end of newsletter */

  /* Extra Pages */
  .ex-header {
    padding-top: 11rem;
    padding-bottom: 9rem;
  }

  .ex-basic-2 .text-container.dark {
    padding: 2.5rem 3rem 2rem 3rem;
  }

  .ex-basic-2 .form-container {
    margin-top: 0;
  }
  /* end of extra pages */
}
/* end of min-width width 768px */

/* Min-width width 992px */
@media (min-width: 992px) {
  /* Navigation */
  .navbar-custom {
    padding: 2.125rem 1.5rem 2.125rem 2rem;
    background: transparent;
    box-shadow: none;
  }

  .navbar-custom .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
  }

  .navbar-custom .nav-item .nav-link {
    padding: 0.25rem 0.75rem;
    color: #eee;
  }

  .navbar-custom.top-nav-collapse {
    padding: 0.5rem 1.5rem 0.5rem 2rem;
    background-color: #fff;
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.05);
  }

  .navbar-custom.top-nav-collapse .nav-item .nav-link {
    color: #333;
  }

  .navbar-custom.top-nav-collapse .nav-item .nav-link:hover,
  .navbar-custom.top-nav-collapse .nav-item .nav-link.active {
    color: #8710d8;
  }

  .navbar-custom .dropdown-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 0.75rem solid rgba(0, 0, 0, 0);
    border-radius: 0.25rem;
  }

  .navbar-custom.top-nav-collapse .dropdown-menu {
    border-top: 0.5rem solid rgba(0, 0, 0, 0);
    box-shadow: 0 0.375rem 0.375rem 0 rgba(0, 0, 0, 0.02);
  }

  .navbar-custom .dropdown-item {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .navbar-custom .dropdown-divider {
    width: 84%;
  }

  .navbar-custom .social-icons {
    display: block;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .navbar-custom .fa-stack {
    width: 2em;
    margin-left: 0.25rem;
    font-size: 0.75rem;
  }

  .navbar-custom .fa-stack-2x {
    color: #8710d8;
    transition: all 0.2s ease;
  }

  .navbar-custom .fa-stack-1x {
    color: #333;
    transition: all 0.2s ease;
  }

  .navbar-custom .fa-stack:hover .fa-stack-2x {
    color: #ee832b;
  }

  .navbar-custom .fa-stack:hover .fa-stack-1x {
    color: #fff;
  }
  /* end of navigation */

  /* General Styles */
  .p-heading {
    width: 65%;
  }
  /* end of general styles */

  /* Header */
  .header h1,
  .header .p-large {
    width: 47rem;
    margin-right: auto;
    margin-left: auto;
  }

  /* end of header */

  /* Registration */

  /* end of registration */

  /* Instructor */
  .basic-1 {
    padding-bottom: 4.5rem;
  }

  .basic-1 img {
    margin-bottom: 0;
  }
  /* end of instructor */

  /* Description */
  .basic-2 {
    padding-top: 4.5rem;
    padding-bottom: 3.5rem;
  }
  /* end of description */

  /* Students */
  .basic-3 {
    padding-top: 3.5rem;
    padding-bottom: 4.5rem;
  }

  .basic-3 .text-container {
    margin-bottom: 0;
  }
  /* end of students */

  /* Details Lightbox */
  .lightbox-basic {
    max-width: 62.5rem;
    padding: 2.5rem 2.5rem 2.5rem 2.5rem;
  }

  .lightbox-basic .image-container {
    max-width: 100%;
    margin-right: 2rem;
    margin-bottom: 0;
    margin-left: 0.5rem;
  }

  .lightbox-basic h3 {
    margin-top: 0.5rem;
  }
  /* end of details lightbox */

  /* Video */
  .basic-4 {
    padding-top: 4.5rem;
    padding-bottom: 3.5rem;
  }
  /* end of video */

  /* Takeaways */
  .cards {
    padding-top: 3.5rem;
    padding-bottom: 3rem;
  }

  .cards .card {
    display: inline-block;
    width: 17.75rem;
    max-width: 100%;
    vertical-align: top;
  }

  .cards .card:nth-child(3n + 2) {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  /* end of takeaways */

  /* Testimonials */
  .slider-2 {
    padding-bottom: 9rem;
  }

  .slider-2 h3 {
    width: 37.5rem;
    margin-right: auto;
    margin-left: auto;
  }

  .slider-2 .swiper-container {
    width: 92%;
    text-align: left;
  }

  .slider-2 .image-wrapper {
    float: left;
    width: 6rem;
    margin-bottom: 0;
  }

  .slider-2 .text-wrapper {
    max-width: 100%;
    margin-left: 7.375rem;
  }

  .slider-2 .swiper-button-prev {
    left: 0;
  }

  .slider-2 .swiper-button-next {
    right: 0;
  }
  /* end of testimonials */

  /* Date */
  .basic-5 h2 {
    width: 70%;
  }
  /* end of date */

  /* Newsletter */
  .form-2 {
    padding-top: 9rem;
  }

  .form-2 h3 {
    width: 37.5rem;
    margin-right: auto;
    margin-left: auto;
  }
  /* end of newsletter */

  /* Contact */
  .form-3 .text-container {
    margin-bottom: 0;
  }
  /* end of contact */

  /* Extra Pages */
  .ex-header h1 {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  .ex-basic-2 {
    padding-bottom: 5rem;
  }
  /* end of extra pages */
}
/* end of min-width width 992px */

/* Min-width width 1200px */
@media (min-width: 1200px) {
  /* Header */
  /* end of header */

  /* Registration */

  .form-1 .text-container {
    margin-right: 3.5rem;
  }

  .form-1 form {
    margin-left: 1.5rem;
  }
  /* end of registration */

  /* Instructor */
  .basic-1 .text-container {
    margin-top: 3.125rem;
    margin-left: 3.5rem;
  }
  /* end of instructor */

  /* Description */
  .basic-2 .list-unstyled.first {
    margin-right: 1.625rem;
  }

  .basic-2 .list-unstyled.second {
    margin-left: 1.625rem;
  }
  /* end of description */

  /* Students */
  .basic-3 .text-container {
    margin-top: 2.5rem;
    margin-right: 3.5rem;
  }
  /* end of instructor */

  /* Takeaways */
  .cards .card {
    width: 21rem;
  }

  .cards .card:nth-child(3n + 2) {
    margin-right: 2.875rem;
    margin-left: 2.875rem;
  }
  /* end of takeaways */

  /* Testimonials */
  .slider-2 .swiper-container {
    width: 90%;
  }

  .slider-2 .text-wrapper {
    margin-top: 0;
  }
  /* end of testimonials */

  /* Date */
  .basic-5 h2 {
    width: 50%;
  }

  .basic-5 p {
    width: 74%;
  }
  /* end of date */

  /* Contact */
  .form-3 .text-container {
    margin-top: 2.25rem;
    margin-right: 3.5rem;
  }
  /* end of contact */

  /* Footer */
  .footer .footer-col.first {
    margin-right: 1.5rem;
  }

  .footer .footer-col.second {
    margin-left: 3rem;
  }

  .footer .footer-col.third {
    margin-left: 2.75rem;
  }

  .footer .footer-col.fourth {
    margin-left: 1.5rem;
  }
  /* end of footer */

  /* Extra Pages */
  .ex-header h1 {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
  }

  .ex-basic-2 .form-container {
    margin-left: 1.75rem;
  }

  .ex-basic-2 .image-container-small {
    margin-left: 1.75rem;
  }
  /* end of extra pages */
}
/* end of min-width width 1200px */

@media screen and (max-width: 768px) {
  body {
    font-size: smaller;
  }
}

code {
  background-color: #d8e6e6;
  padding: 0.2rem 0.5rem;
  border-radius: 0.25rem;
}

pre code {
  background-color: transparent;
  padding: 0;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #cecccc;
  /* border-radius: 50px; */
}

::-webkit-scrollbar-thumb {
  background: #25063a;
  /* border-radius: 50px; */
}

::-webkit-scrollbar-thumb:hover {
  background: #25063a;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.4rem 1rem;
  font-size: 0.9rem;
  color: #070808;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #cdcecf;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}
.accordion-button:focus {
  z-index: 3;
  border-color: #8710d8;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  /* background-color: red; */
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}
