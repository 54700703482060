.profile-img-container {
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}


.profile-image {
    width: 200px;
    height: 200px;
    border-radius: 100%;
}

.edit-icon {
    position: relative;
    top: 80px;
    right: 30px;
}

.profile-heading {
    font-weight: 600;
}

.profile-card-body {
    border: 1px solid #e5e5e5;
}