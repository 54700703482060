pre {
  background-color: #d8dde2;
  padding: 5px 10px;
  border-radius: 5px;
}

.preview-body table {
  /* width: 100%; */
  margin-bottom: 1rem;
  color: #212529;
  border: 1px solid #dee2e6;
}

.preview-body table th,
.preview-body table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  border: 1px solid #dee2e6;
}

.preview-body table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  border-bottom-width: 2px;
}

.preview-body table thead td {
  border-bottom-width: 2px;
}

.preview-body table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.preview-body table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.preview-body table tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.markdown-container {
  padding: 1rem;
  border: 1px solid #dee2e6;
  /*  border-radius: 0.25rem; */
  min-height: 30vh;
  max-height: 40vh;
  overflow: hidden;
  overflow-y: scroll;
  /* background-color: #f6f8fa; */
  /* z-index: 100; */
}

.ask-question-card {
  padding: 0.5rem;
}

.editor-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0.5rem; */
  margin-bottom: 3px;
}

.editor-buttons-container .btn {
  font-size: 0.8rem;
  /* margin-left: 3px; */
}

@media screen and (max-width: 768px) {
  .editor-buttons-container .btn {
    font-size: 0.7rem;
    padding: 0.6rem;
    border-left-width: 1px;
    border-left-color: #212529;
  }

  .preview-container {
    padding: 0;
    margin: 0;
  }

  .preview-body h1 {
    font-size: 1.5rem;
  }

  .preview-body h2 {
    font-size: 1.25rem;
  }

  .preview-body h3 {
    font-size: 1rem;
  }

  .preview-body h4 {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 450px) {
  .editor-buttons-container {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .editor-buttons-container .btn {
    margin-top: 5px;
    font-size: 0.7rem;
    border-left-width: 1px;
    border-left-color: #212529;
    
  }
}

.textarea-question {
  /* resize: none; */
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  min-height: 25vh;
  height: 35vh;
  max-height: 50vh;
}

.textarea-question:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
