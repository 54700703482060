.question-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5em;
  text-transform: capitalize;
}

.markdown-question-container {
  padding: 1rem;
  border: 1px solid #dee2e6;
  min-height: 30vh;
  max-height: 50vh;
  overflow-y: scroll;
}

.markdown-comment-container {
  padding: 1rem;
  border: 1px solid #dee2e6;
  /* min-height: 30vh; */
  max-height: 50vh;
  overflow-y: scroll;
}


.text-qsn-desc {
  /* font-size: 1.2rem; */
  font-weight: bold;
  margin-bottom: 0.5em;
  color: black;
}

.text-qsn-head {
  /* font-size: 1.2rem; */
  font-weight: bold;
  margin-bottom: 0.5em;
  text-decoration: underline;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .question-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5em;
    text-transform: capitalize;
  }

  .icon-buttons-container {
    margin-top: 10px;
  }

  .new-comment-card-body {
    padding: 0;
    margin: 0;
  }
}

.user-avatar {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
}

.icon-buttons-container {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.icon-button {
  margin-right: 5px;
  padding: 7px 14px 7px 14px;
  cursor: pointer;
  border: 0;
}

.icon-button:active {
  outline: 0;
  background: #dee2e6;
}

.text-badge {
  border: 1px solid #999c9e;
}