#amb {
  padding: 4rem 0;
  color: #000;
  zoom: 0.9;
}

#amb b {
  font-weight: 700;
  color: #000;

  font-style: italic;
}

#amb h2 {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3rem;
}

#amb .card {
  margin-top: 2rem;
  padding: 1rem 1rem;
  background-color: #fff;
  border: 1px solid #8710d8;
  border-bottom-left-radius: 5rem;
  border-top: 0;
}

#amb .card.right-border{
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 5rem;
}

#amb .card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 7px;
  background-color: #8710d8;
}

#amb .card .card-header {
  background-color: transparent;
  border: 0;
}

#amb .card h5 {
  color: #000;
  font-weight: 700;
  font-size: 1.3rem;
}

#amb .card .card-body,
#amb .card .card-body p,
#amb .card .card-body b {
  color: #000;
}

#amb a {
  color: #8710d8;
  font-weight: 700;
  text-transform: capitalize;
  font-style: italic;
}

@media screen and (max-width: 768px) {
  #amb h2 {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  #amb .para {
    font-size: 0.9rem;
  }
}

#amb li {
  margin: 0.5rem 0;
}

#amb .btn-primary-outline {
  margin-top: 1rem;
  padding: 1.5rem;
  border-bottom-right-radius: 2rem;
  font-size: 1rem;
  border-top: 5px solid #8710d8;
}